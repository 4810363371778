export const reframe = (target, cName) => {
    let frames = typeof target === 'string' ? document.querySelectorAll(target) : target;
    const c = cName || 'js-reframe';
    if (!('length' in frames)) frames = [frames];
    for (let i = 0; i < frames.length; i += 1) {
        const frame = frames[i];
        const hasClass = frame.className.split(' ').indexOf(c) !== -1;

        if (hasClass || frame.style.width.indexOf('%') > -1) {
            return;
        }

        const width = frame.getAttribute('width') || frame.offsetWidth;
        const height = frame.getAttribute('height') || frame.offsetHeight;
        const padding = (height / width) * 100;

        const div = document.createElement('div');
        div.className = c;

        const divStyle = div.style;
        divStyle.position = 'relative';
        divStyle.width = '100%';
        divStyle.paddingTop = padding + '%';

        const frameStyle = frame.style;
        frameStyle.position = 'absolute';
        frameStyle.width = '100%';
        frameStyle.height = '100%';
        frameStyle.left = '0';
        frameStyle.top = '0';

        frame.parentNode.insertBefore(div, frame);
        frame.parentNode.removeChild(frame);
        div.appendChild(frame);
    }
}